.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  padding: 2rem 0;
  z-index: 1000;
  transition: all 0.5s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header.scrolled {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem 0;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.header-container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 65px;
  width: auto;
  transition: all 0.4s ease;
}

.header.scrolled .logo img {
  height: 50px;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  gap: 4rem;
  margin: 0;
  padding: 0;
}

.nav-menu ul li a {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  padding: 8px 0;
}

/* Når headeren er i toppen (ikke scrolled) */
.header:not(.scrolled) .nav-menu ul li a {
  color: #fff;
}

.nav-menu a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease;
}

.nav-menu a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #1a1a1a;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.header:not(.scrolled) .menu-toggle span {
  background-color: white;
}

/* Styling for krydset når menuen er åben */
.menu-toggle.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
  background-color: #1a1a1a; /* Sikrer at krydset er synligt */
}

.menu-toggle.active span:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
  background-color: #1a1a1a; /* Sikrer at krydset er synligt */
}

@media (max-width: 1200px) {
  .header-container {
    padding: 0 4rem;
  }
  
  .nav-menu ul {
    gap: 3rem;
  }
}

@media (max-width: 1100px) {
  .header {
    padding: 1.5rem 0;
  }
  
  .header-container {
    padding: 0 2rem;
  }
  
  .menu-toggle {
    display: block;
    position: relative;
    z-index: 1000; /* Sikrer at knappen er over mobilmenuen */
  }
  
  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: #fff; /* Fast hvid baggrund */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease;
    z-index: 999;
  }
  
  .nav-menu.active {
    right: 0;
    background-color: #fff; /* Sikrer hvid baggrund når menuen er åben */
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .nav-menu ul li a {
    color: #1a1a1a; /* Sikrer mørk tekst mod den hvide baggrund */
    font-size: 1.2rem;
  }

  .logo img {
    height: 45px;
  }
  
  .header.scrolled .logo img {
    height: 40px;
  }

  /* Når mobilmenuen er åben */
  .nav-menu.active ul li a {
    color: #1a1a1a !important; /* Sikrer mørk tekst i mobilmenuen */
  }

  /* Selv når headeren er i toppen */
  .header:not(.scrolled) .nav-menu.active ul li a {
    color: #1a1a1a !important;
  }
}

.nav-menu ul li a {
  cursor: pointer;
}

.hero {
  height: 100vh;
  position: relative;
  background-image: url('../../../public/media/hero-vin.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  max-width: 1000px;
  padding: 0 2rem;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease forwards;
  animation-delay: 0.5s;
}

.hero-subtitle {
  display: block;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.hero-content h1 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 6rem;
  margin-bottom: 2rem;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 1.1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.hero-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.hero-separator span {
  height: 1px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.6);
}

.wine-icon {
  font-size: 0.8rem;
  opacity: 0.8;
}

.hero-content p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  margin-bottom: 3.5rem;
  line-height: 1.8;
  letter-spacing: 1.5px;
  font-weight: 300;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
}

.cta-button {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 1.4rem 3.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease;
  z-index: -1;
}

.cta-button:hover {
  border-color: white;
  color: #1a1a1a;
}

.cta-button:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 5rem;
  }
}

@media (max-width: 768px) {
  .hero-subtitle {
    font-size: 0.8rem;
    letter-spacing: 3px;
    margin-bottom: 1.5rem;
  }

  .hero-content h1 {
    font-size: 3.5rem;
  }

  .hero-separator {
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .hero-separator span {
    width: 40px;
  }

  .hero-content p {
    font-size: 1rem;
    letter-spacing: 1px;
    margin-bottom: 3rem;
  }
  
  .cta-button {
    padding: 1.2rem 2.5rem;
    font-size: 0.85rem;
  }

  .scroll-indicator {
    bottom: 30px;
  }

  .scroll-arrow {
    height: 30px;
  }

  .hero-cta-container {
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2.8rem;
  }

  .hero-subtitle {
    font-size: 0.75rem;
    letter-spacing: 2px;
  }
}

@media (max-height: 700px) {
  .scroll-indicator {
    display: none;
  }
}

.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards 1.5s;
  transition: transform 0.3s ease;
}

.scroll-indicator:hover {
  transform: translateX(-50%) translateY(-5px);
}

.scroll-text {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 300;
  position: relative;
  padding: 0 10px;
  margin-bottom: 8px;
}

.scroll-text::before,
.scroll-text::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s ease;
  opacity: 0.6;
}

.scroll-text::before {
  right: 100%;
}

.scroll-text::after {
  left: 100%;
}

.scroll-indicator:hover .scroll-text::before,
.scroll-indicator:hover .scroll-text::after {
  width: 20px;
}

.scroll-arrow-down {
  width: 8px;
  height: 8px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  transform: rotate(45deg);
  opacity: 0.6;
  margin-top: 4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.hero-cta-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 6rem 0 3rem;
  position: relative;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  max-width: 800px;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(139, 0, 0, 0), 
    rgba(139, 0, 0, 0.7), 
    rgba(139, 0, 0, 0)
  );
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 6rem;
  margin-bottom: 4rem;
}

.brand-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.footer-logo {
  width: 200px;
  height: auto;
  filter: brightness(0) invert(1);
  opacity: 0.95;
}

.brand-section p {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.25rem;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  font-style: italic;
  letter-spacing: 0.3px;
  margin-top: -0.5rem;
}

.social-links {
  display: flex;
  gap: 2.5rem;
  margin-top: 0.5rem;
}

.social-links a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
  transition: all 0.4s ease;
}

.social-links a:hover {
  color: #8b0000;
  transform: translateY(-3px);
}

.footer-section h4 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  font-weight: 300;
  color: #fff;
  position: relative;
  letter-spacing: 0.5px;
}

.footer-section h4::after {
  content: '';
  position: absolute;
  bottom: -0.8rem;
  left: 0;
  width: 25px;
  height: 1px;
  background: #8b0000;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.footer-section ul li {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  transition: all 0.3s ease;
  letter-spacing: 0.3px;
}

.footer-section ul li:hover {
  color: #fff;
  transform: translateX(5px);
}

.footer-section ul li i {
  color: #8b0000;
  font-size: 1rem;
  width: 20px;
  text-align: center;
  opacity: 0.8;
}

.opening-hours {
  gap: 1rem !important;
}

.opening-hours li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transform: none !important;
}

.opening-hours li:hover {
  transform: none !important;
  color: rgba(255, 255, 255, 0.7);
}

.opening-hours li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.opening-hours span:first-child {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Cormorant Garamond', serif;
  font-style: italic;
  font-size: 1.1rem;
}

.opening-hours span:last-child {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  margin-top: 4rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom-left {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-bottom p {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.cvr {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.smiley-link {
  transition: opacity 0.3s ease;
}

.smiley-link:hover {
  opacity: 0.8;
}

.smiley-icon {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

@media (max-width: 992px) {
  .footer {
    padding: 5rem 0 3rem;
  }

  .footer-grid {
    gap: 4rem;
  }

  .footer-logo {
    width: 180px;
  }

  .footer-section h4::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 4rem 0 2.5rem;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .footer-section {
    text-align: center;
  }

  .brand-section {
    text-align: center;
  }

  .brand-section p {
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }

  .footer-section ul {
    align-items: center;
  }

  .footer-section ul li {
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 0.5rem;
  }

  .footer-section ul li i {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .footer-section ul li span {
    text-align: center;
  }

  .opening-hours li {
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    align-items: center;
  }

  .footer-logo {
    margin: 0 auto;
  }

  .cvr {
    text-align: center;
  }
}

.kontakt {
  padding: 8rem 0;
  background-color: #fff;
  position: relative;
}

.kontakt-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 4rem;
  text-align: center;
}

.kontakt h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  color: #1a1a1a;
}

.kontakt-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  margin-top: 2rem;
  text-align: left;
  position: relative;
  align-items: start;
}

/* Stilfuld separator mellem info og form */
.kontakt-container::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 1px;
  background: linear-gradient(to bottom, transparent, rgba(139, 0, 0, 0.1), transparent);
}

.kontakt-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 2rem;
  height: 100%;
  justify-content: space-between;
}

.info-item {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1.5rem;
  background: #1a1a1a;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.info-item i {
  font-size: 1.5rem;
  color: #8b0000;
  opacity: 0.9;
}

.info-item h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  color: #fff;
}

.info-item p {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.95rem;
  color: #ccc;
  line-height: 1.6;
}

.info-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  background: #222;
}

.kontakt-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 2rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1.2rem;
  background: #fcfcfc;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #1a1a1a;
  transition: all 0.3s ease;
}

.form-group input:hover,
.form-group textarea:hover {
  background: #fff;
  border-color: rgba(139, 0, 0, 0.2);
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  background: #fff;
  border-color: #8b0000;
  box-shadow: 0 0 0 4px rgba(139, 0, 0, 0.1);
}

.form-group textarea {
  height: 180px;
  resize: none;
}

.submit-btn {
  background: #8b0000;
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  margin-top: 1rem;
  align-self: flex-start;
}

.submit-btn:hover {
  background: #700000;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(139, 0, 0, 0.2);
}

@media (max-width: 1200px) {
  .kontakt-container {
    gap: 6rem;
  }
}

@media (max-width: 992px) {
  .kontakt-container {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
  
  .kontakt-container::after {
    display: none;
  }
  
  .kontakt-info,
  .kontakt-form {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .kontakt {
    padding: 6rem 0;
  }

  .kontakt-content {
    padding: 0 2rem;
  }

  .kontakt h2 {
    font-size: 3rem;
  }

  .kontakt-container {
    grid-template-columns: 1fr;
    gap: 3rem;
    margin-top: 3rem;
    padding: 0;
  }

  .kontakt-info,
  .kontakt-form {
    padding: 0;
  }

  .info-item {
    padding: 1.2rem;
    margin: 0;
  }

  .form-group {
    margin: 0;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .submit-btn {
    width: 100%;
    padding: 1rem;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .kontakt h2 {
    font-size: 2.5rem;
  }

  .info-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .info-item i {
    margin-bottom: 0.5rem;
  }
}

.section-intro {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 4rem;
}

.form-status {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
}

.form-status.success {
  background-color: rgba(76, 175, 80, 0.1);
  color: #2e7d32;
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.form-status.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #d32f2f;
  border: 1px solid rgba(244, 67, 54, 0.2);
}

.submit-btn.sending {
  opacity: 0.7;
  cursor: not-allowed;
  background: #999;
}

.kontakt .section-subtitle {
  display: block;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a !important;
  opacity: 0.7 !important;
  font-weight: 500;
  text-shadow: none !important;
}

.voresrom {
  padding: 8rem 0;
  background-color: #1a1a1a;
  position: relative;
}

.voresrom-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 4rem;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 6rem;
  align-items: center;
}

.rom-billede {
  position: relative;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rom-billede img {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.rom-content {
  padding-right: 2rem;
  text-align: center;
}

.section-subtitle {
  display: block;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  color: #ffffff !important;
  opacity: 0.7 !important;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.rom-content h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  line-height: 1.1;
  color: #D4AF37;
}

.subtitle-cara {
  display: block;
  font-size: 3.5rem;
  margin-top: 0.5rem;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin: 0 auto 3rem;
  width: fit-content;
}

.separator span {
  height: 1px;
  width: 60px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
}

.wine-icon {
  color: #fff;
  font-size: 0.8rem;
  opacity: 0.8;
}

.rom-text p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.05rem;
  line-height: 1.9;
  margin-bottom: 2rem;
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.rom-detaljer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.detalje {
  text-align: center;
}

.detalje span {
  display: block;
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #D4AF37;
}

.detalje p {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 2rem;
}

.title-text {
  flex: 1;
}

.title-logo {
  margin: 0 auto 2rem;
  height: 200px;
  display: flex;
  justify-content: center;
}

.title-logo img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .voresrom-container {
    gap: 4rem;
    padding: 0 3rem;
  }

  .rom-content h2 {
    font-size: 4rem;
  }

  .rom-billede {
    height: 600px;
  }
}

@media (max-width: 992px) {
  .voresrom {
    padding: 6rem 0;
  }

  .voresrom-container {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .rom-content {
    padding-right: 0;
    text-align: center;
    order: -1;
  }

  .rom-billede {
    order: 1;
  }

  .separator {
    justify-content: center;
  }

  .rom-detaljer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .title-container {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .title-logo {
    height: 120px;
  }
}

@media (max-width: 768px) {
  .voresrom {
    padding: 5rem 0;
  }

  .voresrom-container {
    padding: 0 2rem;
  }

  .rom-content h2 {
    font-size: 3.5rem;
  }

  .subtitle-cara {
    font-size: 2.8rem;
  }

  .rom-billede {
    height: 500px;
  }

  .rom-detaljer {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  /* Centrering af separator på mobil */
  .separator {
    width: 100%;
    justify-content: center;
    margin: 2rem 0;
  }

  .separator span {
    width: 40px; /* Kortere linjer */
  }

  .separator i {
    margin: 0 1rem;
  }

  .rom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
  }

  .title-logo {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .rom-content h2 {
    font-size: 3rem;
  }

  .subtitle-cara {
    font-size: 2.4rem;
  }

  .title-logo {
    height: 120px;
  }
}

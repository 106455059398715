.brands {
  padding: 8rem 0;
  background-color: #fcfcfc;
  position: relative;
}

.brands-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 4rem;
  text-align: center;
}

.brands h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  color: #1a1a1a;
}

.brands .section-subtitle {
  display: block;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a !important;
  opacity: 0.7 !important;
  font-weight: 500;
  text-shadow: none !important;
}

.section-intro {
  max-width: 800px;
  margin: 0 auto 5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #666;
}

.selections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6rem 8rem;
  margin: 4rem auto 0;
  max-width: 1200px;
  padding: 0 2rem;
}

.selection-group {
  flex: 0 1 300px;
  text-align: center;
}

.selection-group h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #1a1a1a;
  padding-bottom: 1rem;
  position: relative;
  border-bottom: none;
}

.selection-group h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 1px;
  background: rgba(139, 0, 0, 0.4);
}

.brands-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.brands-list span {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #666;
  padding-left: 1.2rem;
  position: relative;
  transition: none;
}

.brands-list span::before {
  content: '';
  position: absolute;
  left: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  background: #8b0000;
  border-radius: 50%;
  opacity: 0.7;
}

.brands-list span:hover {
  transform: none;
  color: #666;
}

@media (max-width: 1400px) {
  .selections-container {
    gap: 5rem 6rem;
  }
}

@media (max-width: 768px) {
  .brands {
    padding: 6rem 0;
  }
  
  .brands-content {
    padding: 0 2rem;
  }
  
  .brands h2 {
    font-size: 3rem;
  }
  
  .selections-container {
    padding: 0;
    gap: 4rem;
  }
  
  .selection-group h3 {
    font-size: 1.8rem;
  }
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
  margin: 0 auto 3rem;
  width: fit-content;
}

.wine-icon {
  color: #8b0000;
  font-size: 0.8rem;
  opacity: 0.8;
}

.separator span {
  height: 1px;
  width: 60px;
  background: linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05));
}

.brands-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 4rem;
}

.brand-box {
  background: #1a1a1a;
  padding: 4rem;
  border-radius: 8px;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.brand-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.brand-content h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
  font-weight: 400;
}

.brand-content p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.05rem;
  line-height: 1.8;
  color: #666;
  margin-bottom: 2rem;
}

.brand-features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.brand-features li {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  padding: 0.8rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.brand-features li::before {
  content: '♦';
  position: absolute;
  left: 0;
  color: #ffffff;
  opacity: 0.8;
  font-size: 0.8rem;
}

@media (max-width: 992px) {
  .brands-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .brand-box {
    padding: 3rem;
  }
}

@media (max-width: 768px) {
  .brands {
    padding: 6rem 0;
  }

  .brands-content {
    padding: 0 2rem;
  }

  .brand-box {
    padding: 2rem;
  }

  .brand-content h3 {
    font-size: 2rem;
  }
}

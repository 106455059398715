.showcase {
  padding: 4rem 0;
  background-color: #fcfcfc;
  position: relative;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.showcase::before,
.showcase::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150px;
  z-index: 2;
  pointer-events: none;
}

.showcase::before {
  left: 0;
  background: linear-gradient(to right, 
    #fcfcfc 0%,
    rgba(252, 252, 252, 0) 100%
  );
}

.showcase::after {
  right: 0;
  background: linear-gradient(to left, 
    #fcfcfc 0%,
    rgba(252, 252, 252, 0) 100%
  );
}

.slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider-track {
  display: flex;
  align-items: center;
  will-change: transform;
}

.logo-item {
  flex: 0 0 auto;
  padding: 0 3rem;
  opacity: 0.6;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 120px;
}

.logo-item:hover {
  opacity: 1;
}

.logo-item img {
  max-width: 200px;
  max-height: 120px;
  width: auto;
  height: auto;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.4s ease;
}

.logo-item:hover img {
  filter: grayscale(0%);
}

@media (max-width: 768px) {
  .showcase {
    padding: 3rem 0;
  }

  .showcase::before,
  .showcase::after {
    width: 80px;
  }

  .logo-item {
    padding: 0 2rem;
    width: 180px;
    height: 100px;
  }

  .logo-item img {
    max-width: 160px;
    max-height: 100px;
  }
}

@media (max-width: 480px) {
  .showcase {
    padding: 2rem 0;
  }

  .logo-item {
    padding: 0 1.5rem;
    width: 140px;
    height: 90px;
  }

  .logo-item img {
    max-width: 140px;
    max-height: 90px;
  }
}

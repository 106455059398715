.om-section {
  padding: 10rem 0;
  background-color: #fcfcfc;
  position: relative;
  overflow: hidden;
}

.om-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(0,0,0,0), 
    rgba(0,0,0,0.1), 
    rgba(0,0,0,0)
  );
}

.om-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 4rem;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 6rem;
  align-items: center;
}

.om-content {
  padding-right: 2rem;
  position: relative;
}

.om-subtitle {
  display: block;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
  opacity: 0.7;
}

.om-content h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  line-height: 1.1;
  color: #1a1a1a;
}

.om-separator {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.om-separator span {
  height: 1px;
  width: 60px;
  background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.05));
}

.wine-icon {
  color: #1a1a1a;
  font-size: 0.8rem;
  opacity: 0.4;
}

.om-text {
  position: relative;
}

.om-text p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.05rem;
  line-height: 1.9;
  margin-bottom: 2rem;
  color: #333;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.om-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.feature {
  text-align: left;
  position: relative;
  padding-left: 2rem;
}

.feature::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 1px;
  height: 40px;
  background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.05));
}

.feature h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #1a1a1a;
  letter-spacing: 0.5px;
}

.feature p {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.95rem;
  line-height: 1.7;
  color: #666;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.om-image {
  position: relative;
  height: 700px;
  overflow: hidden;
}

.om-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1.2s ease;
}

.om-image:hover img {
  transform: none;
}

.image-overlay {
  display: none;
}

@media (max-width: 1200px) {
  .om-container {
    gap: 4rem;
    padding: 0 3rem;
  }

  .om-content h2 {
    font-size: 4rem;
  }

  .om-image {
    height: 600px;
  }
}

@media (max-width: 992px) {
  .om-section {
    padding: 8rem 0;
  }

  .om-container {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .om-content {
    padding-right: 0;
  }

  .om-image {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .om-section {
    padding: 6rem 0;
  }

  .om-container {
    padding: 0 2rem;
  }

  .om-content h2 {
    font-size: 3.5rem;
  }

  .om-features {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .feature {
    padding-left: 1.5rem;
  }
}

@media (max-width: 480px) {
  .om-content h2 {
    font-size: 3rem;
  }

  .om-subtitle {
    font-size: 0.8rem;
    letter-spacing: 3px;
  }

  .om-text p {
    font-size: 1rem;
  }
}

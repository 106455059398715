.profilprodukter {
  padding: 8rem 0;
  background-color: #fcfcfc;
  position: relative;
}

.profilprodukter-content {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  padding: 0 4rem;
}

.profilprodukter h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  color: #1a1a1a;
}

.profilprodukter .section-subtitle {
  display: block;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a !important;
  opacity: 0.7 !important;
  font-weight: 500;
  text-shadow: none !important;
}

.section-intro {
  max-width: 800px;
  margin: 0 auto 5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #666;
  font-weight: 300;
}

.produkter-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
  margin-top: 2rem;
}

.produkt-kort {
  background: #fff;
  text-align: left;
  transition: all 0.3s ease;
  position: relative;
}

.produkt-kort:hover {
  transform: translateY(-5px);
}

.produkt-billede {
  position: relative;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.produkt-billede img {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: transform 0.6s ease;
  padding: 2rem 3rem;
}

.produkt-kort:hover .produkt-billede img {
  transform: scale(1.03);
}

.produkt-info {
  padding: 2rem;
  background: #fff;
}

.produkt-info h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #1a1a1a;
}

.produkt-type {
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  color: #8b0000;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.produkt-info p {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.95rem;
  line-height: 1.7;
  color: #666;
  font-weight: 300;
}

@media (max-width: 1400px) {
  .produkter-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .produkter-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .produkter-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .profilprodukter {
    padding: 6rem 0;
  }

  .profilprodukter h2 {
    font-size: 3.5rem;
  }

  .produkt-billede {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .profilprodukter h2 {
    font-size: 3rem;
  }

  .section-intro {
    font-size: 1rem;
  }

  .produkt-billede {
    height: 300px;
  }
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
  margin: 0 auto 3rem;
  width: fit-content;
}

.wine-icon {
  color: #8b0000;
  font-size: 0.8rem;
  opacity: 0.8;
}

.separator span {
  height: 1px;
  width: 60px;
  background: linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05));
}

.alkoholfri-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #8b0000;
  color: white;
  padding: 10px 15px;
  border-radius: 50%;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 2;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
